import React, { Component } from 'react';

import {
  Layout,
  Row,
  Col,
  Tooltip,
  Icon,
  Table,
  Button,
  Input,
  Tag,
  Modal,
  Form,
  Select,
  notification,
  Divider,
} from 'antd';
import MaskedInput from 'react-text-mask';
import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import { medicalPlansActions, selectsActions } from '../../actions';
import { medicalPlansService } from '../../services';
import { stringsHelp } from '../../helpers';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const FormItem = Form.Item;
const { Option } = Select;
const { Search } = Input;
const cnpjMask = [
  /\d/, /\d/, '.', 
  /\d/, /\d/, /\d/, '.', 
  /\d/, /\d/, /\d/, '/', 
  /\d/, /\d/, /\d/, /\d/, '-', 
  /\d/, /\d/
];

class MedicalPlans extends Component {
  state = {
    modalVisible: false,
    actionNew: true,
    loadingSave: false,
    confirmVisible: false,
    confirmLoading: false,
    delete_uuid: undefined,
    dataMedicalPlans: [],
    maskKey: 0,
    searchText: undefined,
  };

  cancelSource = CancelToken.source();

  load = () => {
    const { dispatch, medicalPlans } = this.props;

    if (medicalPlans.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    dispatch(selectsActions.getTables());
    dispatch(medicalPlansActions.getMedicalPlans({}, this.cancelSource.token)).then((data) => {
      if (this.state.searchText)
        this.filterMedicalPlans(this.state.searchText);
      else
        this.setState({
          dataMedicalPlans: data,
        });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let promiseSave;

        if (values.id) {
          this.setState({ loadingSave: true });

          const objTable = this.props.tables.data.find(
            (s) => s.id === values.medical_plan_table_id,
          );
          values.table_name = objTable.name;

          promiseSave = medicalPlansService.putMedicalPlan(values).then((result) => {
            if (result.id > 0) {
              this.handleCancel();

              notification.success({
                message: 'Convênio alterado com sucesso!',
              });

              this.load();
            }
          });
        } else {
          this.setState({ loadingSave: true });

          delete values.id;
          delete values.uuid; // remove before post
          const objTable = this.props.tables.data.find(
            (s) => s.id === values.medical_plan_table_id,
          );
          values.table_name = objTable.name;

          promiseSave = medicalPlansService
            .postMedicalPlan(values)
            .then((result) => {
              if (result.id > 0) {
                this.handleCancel();

                notification.success({
                  message: 'Convênio cadastrado com sucesso!',
                });

                this.load();
              }
            });
        }

        promiseSave.catch((error) => {
          console.log(error);
          this.setState({ loadingSave: false });
          notification.error({
            message: error,
          });
        });
      }
    });
  };

  handleCancel = () => {
    const clearMedicalPlan = {
      name: '',
      id: null,
      uuid: null,
      medical_plan_table_id: null,
      description: '',
      document: '',
    };

    this.setState({ modalVisible: false, loadingSave: false });
    this.props.form.setFieldsValue(clearMedicalPlan);
  };

  openModal = (p_id) => {
    if (p_id) {
      mixPanelService.tracking(mixPanelService.CONVENIO_EDITAR);

      const sectorObject = this.props.medicalPlans.data.find(
        (s) => s.id === p_id,
      );

      const editMedicalPlan = {
        name: sectorObject.name,
        id: sectorObject.id,
        uuid: sectorObject.uuid,
        document: sectorObject.document,
        description: sectorObject.description,
        medical_plan_table_id: sectorObject.medical_plan_table_id,
      };

      this.setState({ modalVisible: true, actionNew: false });

      this.props.form.setFieldsValue(editMedicalPlan);
    } else {
      mixPanelService.tracking(mixPanelService.CONVENIO_ADD);

      const newMedicalPlan = {
        name: '',
        id: null,
        uuid: null,
        medical_plan_table_id: null,
        description: '',
        document: '',
      };

      this.setState({ modalVisible: true, actionSector: true });
      this.props.form.setFieldsValue(newMedicalPlan);
    }
  };

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_CONVENIO);
    this.load();
  }

  handleRoomCancel = () => {
    const clearMedicalPlan = {
      surgery_center_id: null,
      name: '',
      id: null,
      uuid: null,
    };

    this.setState({ modalVisible: false, loadingSave: false });
    this.props.form.setFieldsValue(clearMedicalPlan);
  };

  showConfirm = (uuid) => {
    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      delete_uuid: uuid,
    });
  };

  handleDelete = () => {
    let promiseSave;

    if (this.state.delete_uuid) {
      this.setState({ confirmLoading: true });

      mixPanelService.tracking(mixPanelService.CONVENIO_REMOVER);

      promiseSave = medicalPlansService
        .deleteMedicalPlan(this.state.delete_uuid)
        .then((result) => {
          notification.success({
            message: 'Convênio removido com sucesso!',
          });

          this.load();

          this.setState({
            confirmLoading: false,
            confirmVisible: false,
            delete_id: null,
          });
        });

      promiseSave.catch((error) => {
        console.log(error);
        notification.error({
          message: 'Falha ao remover convênio, tente novamente mais tarde!',
        });
      });
    }
  };

  searchTable = (e) => {
    const { medicalPlans } = this.props;
    const text = e.target.value;
    this.setState({ searchText: text });

    setTimeout(() => {
      if (text.length) {
        this.filterMedicalPlans(text);
      } else {
        this.setState({ dataMedicalPlans: medicalPlans.data });
      }
    }, 300);
  };

  filterMedicalPlans = (text) => {
    const { medicalPlans } = this.props;
    
    const newDataMedicalPlans = medicalPlans.data.filter((x) => (
      stringsHelp
        .removeAccent(x.name.toString().toLowerCase())
        .includes(stringsHelp.removeAccent(text.toLowerCase()))
        || (x.document && stringsHelp
          .removeAccent(x.document.toString().toLowerCase())
          .includes(stringsHelp.removeAccent(text.toLowerCase())))
      || stringsHelp
        .removeAccent(x.table_name.toString().toLowerCase())
        .includes(stringsHelp.removeAccent(text.toLowerCase()))
    ));
    this.setState({ dataMedicalPlans: newDataMedicalPlans });
  }

  letOnlySusAndTussPreset(table) {
    return true;
  }

  render() {
    const { medicalPlans, tables } = this.props;
    const filteredTables = tables ? tables.data.filter(this.letOnlySusAndTussPreset) : [];
    const user = JSON.parse(localStorage.getItem('user'));
    const selectedInstitution = JSON.parse(localStorage.getItem('selected-institution'));
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
      colon: false,
    };

    const columnsTableMedicalPlans = [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'CNPJ',
        dataIndex: 'document',
        key: 'document',
      },
      {
        title: 'Tabela',
        dataIndex: 'table_name',
        key: 'table_name',
        render: (table_name) => (
          <span>
            <Tag color="#ddf6f6" style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
              {table_name}
            </Tag>
          </span>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (id, record) => (
          <span>
            <Tooltip title="Editar">
              <Button type="link" onClick={() => this.openModal(id)} htmlType="button" style={{ padding: 0 }}>
                <Icon type="edit" />
              </Button>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Remover">
              <Button type="link" onClick={() => this.showConfirm(record.uuid)} style={{ padding: 0 }}>
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <button
              onClick={() => this.openModal(null)}
              className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure"
            >
              <Icon type="plus" fill="#ffffff" />
            </button>
          </Col>
          <Col xs={24} lg={8} sm={16}>
            <h1 className="title">
              Convênios

            </h1>
          </Col>
          <Col xs={24} sm={0}>
            <Search
              placeholder="Buscar"
              disabled={medicalPlans.loading}
              onChange={this.searchTable}
              style={{ width: '100%', marginRight: 10, marginBottom: 10 }}
            />
          </Col>

          <Col xs={0} lg={16} sm={8}>
            <div style={{ display: 'flex' }}>
              <Search
                placeholder="Buscar"
                disabled={medicalPlans.loading}
                onChange={this.searchTable}
                style={{ width: '100%', marginRight: 10 }}
              />
              {(user.type === 'AD' || selectedInstitution.is_admin === 1) && (
              <button
                className="add-procedure"
                onClick={() => this.openModal(null)}
              >
                ADICIONAR
              </button>
              )}

            </div>
          </Col>
        </Row>
        <Row>
          <Table
            size="midle"
            loading={medicalPlans.loading}
            dataSource={this.state.dataMedicalPlans}
            columns={columnsTableMedicalPlans}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100'] }}
            footer={() => {
              const rowsDataSource = !this.state.dataMedicalPlans ? 0 : this.state.dataMedicalPlans.length;
              return (
                <span>
                  Total de registros:
                  {' '}
                  <Tag>{rowsDataSource}</Tag>
                </span>
              );
            }}
          />
        </Row>

        <Modal
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          title={(
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleCancel}
              >
                <i className="icon-close" />
              </button>
              <span>
                {this.state.actionNew
                  ? 'Adicionar convênio'
                  : 'Editar convênio'}
              </span>
            </div>
          )}
          footer={[
            <Button
              key="submit"
              type="primary"
              form="formMedicalPlan"
              htmlType="submit"
              loading={this.state.loadingSave}
            >
              Salvar
            </Button>,
          ]}
          bodyStyle={{ paddingTop: '21px' }}
          width="701px"
          zIndex={1024}
        >
          <Form onSubmit={this.handleSubmit} id="formMedicalPlan">
            <Row gutter={28}>
              <Col md={24} lg={24} xl={14}>
                {getFieldDecorator('id', {})(<span />)}
                {getFieldDecorator('uuid', {})(<span />)}

                <FormItem {...formItemLayout} label="NOME DO CONVÊNIO">
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o nome do convênio.',
                      },
                    ],
                  })(<Input autoFocus />)}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={10}>
              <FormItem {...formItemLayout} label="CNPJ">
    {getFieldDecorator('document', {})(
      <MaskedInput
        key={this.state.maskKey}  // Use a chave aqui
        mask={cnpjMask}
        guide={false}
        className="ant-input"
      />,
    )}
  </FormItem>

              </Col>

              <Col md={24} lg={24} xl={14}>
                <FormItem {...formItemLayout} label="DESCRIÇÃO">
                  {getFieldDecorator('description', {})(<Input />)}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={10}>
                <FormItem {...formItemLayout} label="Tabela">
                  {getFieldDecorator('medical_plan_table_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecione a tabela.',
                      },
                    ],
                  })(
                    <Select>
                      {!tables
                        ? null
                        : filteredTables.map((table) => (
                          <Option key={table.id} value={table.id}>
                            {table.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title=""
          visible={this.state.confirmVisible}
          onOk={() => this.handleDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={this.state.confirmLoading}
          onCancel={() => this.setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: '#ffcd56', marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>
            {' '}
            Confirma remover o convênio?
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { medicalPlans, selects } = state;
  const { tables } = selects;
  return {
    medicalPlans,
    tables,
  };
}

const connected = connect(mapStateToProps)(Form.create()(MedicalPlans));
export default connected;
