/* eslint-disable import/no-cycle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { generatePath, Link } from 'react-router-dom';

import {
  Layout,
  Row,
  Col,
  Form,
  Tooltip,
  Icon,
  Table,
  Button,
  Dropdown,
  Menu,
  Input,
  Tag,
  Divider,
  Modal,
  Select,
  notification,
} from 'antd';

import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import { pathRoutes } from '../../routes';
import { eventsActions } from '../../actions';
import { stringsHelp } from '../../helpers';
import { eventsService } from '../../services';
import ImportTransEventPresets from '../ImportTransEventPresets';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

class Events extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    modalVisiblePreset: false,
    confirmVisible: false,
    confirmLoading: false,
    deleteId: undefined,
    dataEvents: [],
    addDropdownArrow: 'down',
    origin: '',
    search: '',
    searchInterval: null,
    count: 0,
    pagination: {
      defaultPageSize: 10,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '30', '50', '100'],
    },
  };

  cancelSource = CancelToken.source();

  componentDidMount() {
    this.loadMap();
  }

  loadMap = () => {
    const { dispatch, events } = this.props;

    if (events.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    const objFilter = {
      origin: this.state.origin,
      search: this.state.search,
    };

    const searchLocalStorage = localStorage.getItem('searchText');
    const currentPageLocalStorage = localStorage.getItem('currentPage');

    if (searchLocalStorage) {
      objFilter.search = searchLocalStorage;
      this.setState({ search: searchLocalStorage });
      localStorage.removeItem('searchText');
    }

    if (currentPageLocalStorage) {
      this.page = currentPageLocalStorage;
      localStorage.removeItem('currentPage');
    }

    dispatch(eventsActions.getTransEvents(objFilter, this.page, this.state.pagination.defaultPageSize, this.cancelSource.token)).then(
      (data) => {
        this.changePage(this.page,  objFilter.search, this.state.pagination.defaultPageSize);
        this.setState({
          dataEvents: data.events,
          count: data.total,
          pagination: {
            ...this.state.pagination,
            total: data.total,
            onChange: (page, pageSize) => this.changePage(page, objFilter.search, pageSize),
            onShowSizeChange: (current, size) => this.changePageSize(current, size),
          },
        });
      },
    );
  };

  changePageSize = (currentPage, pageSize) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        pageSize,
      },
    }, () => {
      this.changePage(currentPage, this.state.search, pageSize);
    });
  };

  showConfirm = (id) => {
    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      deleteId: id,
    });
  };

  handleDelete = () => {
    let promiseSave;
    if (this.state.deleteId) {
      this.setState({ confirmLoading: true });

      promiseSave = eventsService
        .putTransEvent({ id: this.state.deleteId, active: false })
        .then(() => {
          notification.success({
            message: 'Evento removido com sucesso!',
          });

          this.loadMap();

          this.setState({
            confirmLoading: false,
            confirmVisible: false,
            deleteId: null,
          });
        });

      promiseSave.catch(() => {
        notification.error({
          message: 'Falha ao remover Evento, tente novamente mais tarde!',
        });
      });
    }
  };

  searchTable = (e) => {
    this.setState({ search: e.target.value });

    setTimeout(() => {
      this.loadMap();
    }, 300);
  };

  setOrigin = (type, value) => {
    this.setState({ origin: value });

    if (!this.state.searchInterval) { clearTimeout(this.state.searchInterval); }

    this.state.searchInterval = setTimeout(() => {
      this.loadMap();
    }, 500);
  };

  changePage = (page, filter, pageSize = this.state.pagination.pageSize) => {
    this.cancelSource.cancel();
    this.cancelSource = CancelToken.source();
    let location;
    if (filter) {
      location = generatePath(pathRoutes.events, { page, filter });
    } else {
      location = generatePath(pathRoutes.events, { page });
    }
    this.props.history.push(location);

    const { dispatch } = this.props;

    this.page = page;
    this.filterValue = filter;

    dispatch(
      eventsActions.getTransEvents({ search: filter }, page, pageSize, this.cancelSource.token),
    ).then((data) => {
      this.setState({
        dataEvents: data.events,
        count: data.total,
        pagination: {
          total: data.total,
          defaultPageSize: 10,
          current: page,
        },
      });
    });
  };

  addArrowChange = (a) => {
    if (a) {
      this.setState({ addDropdownArrow: 'up' });
    } else {
      this.setState({ addDropdownArrow: 'down' });
    }
  };

  setModalVisiblePreset = (modalVisiblePreset) => {
    this.setState({ modalVisiblePreset });
    this.addArrowChange(false);
    if (!modalVisiblePreset) {
      this.loadMap();
    }
  };

  render() {
    const { events } = this.props;
    const menuAdd = (
      <Menu>
        <Menu.Item key="add" onClick={() => this.props.history.push({ pathname: pathRoutes.newEvent })}>
          <span>EVENTO</span>
        </Menu.Item>
        <Menu.Item key="import" onClick={() => this.setModalVisiblePreset(true)}>
          <span>PRESET</span>
        </Menu.Item>
      </Menu>
    );

    const columnsTableEvents = [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => (
          <div>
            <h4 style={{ color: '#343f5c' }}>
              {name}
            </h4>
            <Tooltip title="Descrição">
              {record.institution_config.description !== '' ? stringsHelp.wordLimiter(record.institution_config.description, 4, true) : 'Descrição não cadastrada.'}
            </Tooltip>
          </div>
        ),
      },
      {
        title: 'Origem',
        dataIndex: 'origin',
        key: 'specialities',
        width: 120,
        render: (name) => (
          name === 'PRESET'
            ? (
              <Tag key="Preset">Preset</Tag>
            )
            : (
              <Tag key="Instituição">Instituição</Tag>
            )
        ),
      },
      {
        title: '',
        key: 'actions',
        align: 'right',
        dataIndex: 'id',
        width: 80,
        render: (id, record) => (
          <span>
            <Tooltip title="Editar">
              <Button type="link" onClick={() => {
                localStorage.setItem('searchText', this.state.search);
                localStorage.setItem('currentPage', this.page ? this.page : 1);
              }}>
                <Link to={`${pathRoutes.editEvent.replace('/:id', '')}/${id}`}>
                  <Icon type="edit" />
                </Link>
              </Button>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Remover">
              <Button type="link" onClick={() => this.showConfirm(record.id)}>
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <Content>
        <ImportTransEventPresets
          visible={this.state.modalVisiblePreset}
          setVisible={this.setModalVisiblePreset}
        />
        <Row>
          <Col xs={24} sm={24} lg={24} xl={0}>
            <Dropdown
              overlay={menuAdd}
              trigger={['click']}
              overlayStyle={{ width: 150 }}
            >
              <div>
                <Button type="primary" size="large" shape="circle" className="add-procedure">
                  <Icon type="plus" fill="#ffffff" />
                </Button>
              </div>
            </Dropdown>

          </Col>

          <Col xs={24} sm={10} lg={24} xl={8}>
            <h1 className="title">Eventos</h1>
          </Col>
          <Col xs={24} lg={24} xl={16}>
            <Row>
              <Col
                xs={24}
                lg={24}
                sm={24}
                xl={4}
                xxl={4}
                style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 5 }}
              >
                <Select
                  allowClear
                  placeholder="Todos os eventos"
                  loading={events.loading}
                  style={{ width: '100%' }}
                  onChange={(e) => this.setOrigin('origin', e)}
                  showAction={['click']}
                >
                  <Option key="PRESET" value="PRESET">Preset</Option>
                  <Option key="USER" value="USER">Instituição</Option>
                </Select>
              </Col>
              <Col
                xs={24}
                lg={24}
                sm={24}
                xl={14}
                xxl={16}
                style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 5 }}
              >
                <Search
                  placeholder="Buscar"
                  value={this.state.search}
                  onChange={this.searchTable}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={0} sm={0} md={0} lg={0} xl={6} xxl={4} style={{ paddingLeft: 5 }}>
                <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
                  <Dropdown overlay={menuAdd} onVisibleChange={this.addArrowChange}>
                    <Button type="primary" shape="round" className="add-procedure" style={{ width: '100%' }}>
                      ADICIONAR &nbsp;
                      <Icon type={this.state.addDropdownArrow} />
                    </Button>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Col>

        </Row>
        <Row>
          <Table
            size="midle"
            loading={events.loading}
            dataSource={this.state.dataEvents}
            columns={columnsTableEvents}
            pagination={this.state.pagination}
            footer={() => {
              const rowsDataSource = !this.state.count
                ? 0
                : this.state.count;
              return (
                <span>
                  Total de registros:
                  {' '}
                  <Tag>{rowsDataSource}</Tag>
                </span>
              );
            }}
          />
        </Row>

        <Modal
          title=""
          visible={this.state.confirmVisible}
          onOk={() => this.handleDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={this.state.confirmLoading}
          onCancel={() => this.setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: '#ffcd56', marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>
            {' '}
            Confirma remover o Evento ?
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { events } = state;
  return {
    events,
  };
}

const connected = connect(mapStateToProps)(Form.create()(Events));
export default connected;
