import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';

import {
  Layout,
  Row,
  Col,
  Tooltip,
  Icon,
  Table,
  Button,
  Input,
  Modal,
  Form,
  notification,
  Divider,
  InputNumber,
  Tag,
  Alert,
  Avatar,
} from 'antd';
import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import { materialsActions } from '../../actions';
import { materialsService } from '../../services';
import { stringsHelp, numbers } from '../../helpers';
import ImportExcel from '../ImportExcel';
import SelectInstitutionsGroup from '../SelectInstitutionsGroup';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const FormItem = Form.Item;
const { TextArea } = Input;
const { Search } = Input;

class Materials extends Component {
  state = {
    modalVisible: false,
    actionNew: true,
    loadingSave: false,
    confirmVisible: false,
    confirmLoading: false,
    delete_uuid: undefined,
    dataMaterials: [],
    selectGroupInstitutionId: null,
    searchText: undefined,
  };

  cancelSource = CancelToken.source();

  load = () => {
    const { dispatch, materials } = this.props;

    if (materials.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    let objFilter = {};
    if (this.state.selectGroupInstitutionId) {
      objFilter = {
        params: { institution_id: this.state.selectGroupInstitutionId },
      };
    }

    dispatch(
      materialsActions.getMaterials(objFilter, this.cancelSource.token),
    ).then((data) => {
      if (this.state.searchText)
        this.filterMaterials(this.state.searchText);
      else 
        this.setState({
          dataMaterials: data.data,
        });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.price = values.price
          ? numbers.numberToDoubleDecimal(values.price)
          : null;

        let promiseSave;

        if (values.id) {
          this.setState({ loadingSave: true });

          if (this.state.selectGroupInstitutionId) {
            values.institution_id = this.state.selectGroupInstitutionId;
          }

          promiseSave = materialsService.putMaterial(values).then((result) => {
            if (result.id > 0) {
              this.handleCancel();

              notification.success({
                message: 'Material alterado com sucesso!',
              });

              this.load();
            }
          });
        } else {
          this.setState({ loadingSave: true });

          delete values.id;

          if (this.state.selectGroupInstitutionId) {
            values.institution_id = this.state.selectGroupInstitutionId;
          }

          promiseSave = materialsService.postMaterial(values).then((result) => {
            if (result.id > 0) {
              this.handleCancel();

              notification.success({
                message: 'Material cadastrado com sucesso!',
              });

              this.load();
            }
          });
        }

        promiseSave.catch((error) => {
          console.log(error);
          this.setState({ loadingSave: false });
          notification.error({
            message: error,
          });
        });
      }
    });
  };

  handleCancel = () => {
    const clearMedicalPlan = {
      name: '',
      id: null,
      uuid: null,
      medical_plan_table_id: null,
      description: '',
      document: '',
    };

    this.setState({ modalVisible: false, loadingSave: false });
    this.props.form.setFieldsValue(clearMedicalPlan);
  };

  openModal = (p_id) => {
    if (p_id) {
      mixPanelService.tracking(mixPanelService.MATERIAL_EDITAR);

      const materialObject = this.props.materials.data.find((s) => s.id === p_id);

      const editMaterial = {
        name: materialObject.name,
        id: materialObject.id,
        internal_code: materialObject.internal_code,
        price: materialObject.price
          ? parseFloat(materialObject.price)
            .toFixed(2)
            .replace('.', '')
          : null,
        description: materialObject.description,
      };

      this.setState({ modalVisible: true });
      this.props.form.setFieldsValue(editMaterial);
    } else {
      mixPanelService.tracking(mixPanelService.MATERIAL_ADD);

      const newMaterial = {
        name: '',
        id: null,
        internal_code: null,
        price: '',
        description: '',
      };

      this.setState({ modalVisible: true });
      this.props.form.setFieldsValue(newMaterial);
    }
  };

  componentDidMount() {
    if (
      JSON.parse(localStorage.getItem('selected-institution'))
      && JSON.parse(localStorage.getItem('selected-institution')).is_group
    ) { return; }

    mixPanelService.tracking(mixPanelService.MENU_MATERIAL);
    this.load();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 200, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 100, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleRoomCancel = () => {
    const clearMedicalPlan = {
      surgery_center_id: null,
      name: '',
      id: null,
      uuid: null,
    };

    this.setState({ modalVisible: false, loadingSave: false });
    this.props.form.setFieldsValue(clearMedicalPlan);
  };

  showConfirm = (uuid) => {
    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      delete_uuid: uuid,
    });
  };

  handleDelete = () => {
    let promiseSave;

    if (this.state.delete_uuid) {
      this.setState({ confirmLoading: true });

      mixPanelService.tracking(mixPanelService.MATERIAL_REMOVER);

      promiseSave = materialsService
        .deleteMaterial(this.state.delete_uuid)
        .then((result) => {
          notification.success({
            message: 'Material removido com sucesso!',
          });

          this.load();

          this.setState({
            confirmLoading: false,
            confirmVisible: false,
            delete_id: null,
          });
        });

      promiseSave.catch((error) => {
        console.log(error);
        notification.error({
          message: 'Falha ao remover material, tente novamente mais tarde!',
        });
      });
    }
  };

  searchTable = (e) => {
    const { materials } = this.props;
    const text = e.target.value;

    this.setState({ searchText: text });

    setTimeout(() => {
      if (text.length) {
        this.filterMaterials(text);
      } else {
        this.setState({ dataMaterials: materials.data });
      }
    }, 300);
  };

  filterMaterials = (text) => {
    const { materials } = this.props;

    const newDataMaterials = materials.data.filter((x) => (
      stringsHelp
        .removeAccent(x.name.toString().toLowerCase())
        .includes(stringsHelp.removeAccent(text.toLowerCase()))
        || (x.internal_code
          && stringsHelp
            .removeAccent(x.internal_code.toString().toLowerCase())
            .includes(stringsHelp.removeAccent(text.toLowerCase())))
        || (x.description
          && stringsHelp
            .removeAccent(x.description.toString().toLowerCase())
            .includes(stringsHelp.removeAccent(text.toLowerCase())))
    ));

    this.setState({ dataMaterials: newDataMaterials });
  };

  filterInstitutionGroup = (value) => {
    if (!value) return;

    this.setState({ selectGroupInstitutionId: value }, () => {
      this.load();
    });
  };

  render() {
    const { materials, institutions } = this.props;

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
      colon: false,
    };

    const columnsTableMaterials = [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Cód. Interno',
        dataIndex: 'internal_code',
        key: 'internal_code',
      },
      {
        title: 'Preço',
        dataIndex: 'price',
        key: 'price',
        render: (price) => (
          <span>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(price)}
          </span>
        ),
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (id, record) => (
          <span>
            <Tooltip title="Editar">
              <Button type="link" onClick={() => this.openModal(id)}>
                <Icon type="edit" />
              </Button>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Remover">
              <Button type="link" onClick={() => this.showConfirm(record.id)}>
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <Content>
        {JSON.parse(localStorage.getItem('selected-institution'))
        && JSON.parse(localStorage.getItem('selected-institution')).is_group ? (
          <div>
            <Row>
              <Col xs={24} sm={0}>
                {this.state.selectGroupInstitutionId ? (
                  <Button
                    type="primary"
                    shape="circle"
                    size="large"
                    className="add-procedure"
                    onClick={() => this.openModal()}
                  >
                    <Icon type="plus" fill="#ffffff" />
                  </Button>
                ) : <p>&nbsp;</p>}
              </Col>
              <Col xs={24} sm={16}>
                <h1 className="title">Materiais</h1>
              </Col>
              <Col xs={24} sm={0}>
                <div style={{ marginBottom: 10 }}>
                  <SelectInstitutionsGroup onChange={this.filterInstitutionGroup} />
                </div>
              </Col>
              <Col xs={0} sm={8} xxl={8}>
                <div style={{ display: 'flex' }}>
                  <SelectInstitutionsGroup onChange={this.filterInstitutionGroup} />
                </div>
              </Col>

              {this.state.selectGroupInstitutionId ? (
                <div>
                  <Col span={24}>
                    <div style={{ display: 'flex', paddingBottom: 10, paddingTop: 10 }}>
                      <Search
                        placeholder="Buscar"
                        disabled={materials.loading}
                        onChange={this.searchTable}
                        style={{ width: '100%', marginRight: 10 }}
                      />
                      <ImportExcel
                        table="materials"
                        title="Materiais"
                        type="materials"
                        reload={this.load}
                      />
                      <Button
                        type="primary"
                        shape="round"
                        className="add-procedure"
                        onClick={() => this.openModal()}
                      >
                        ADICIONAR
                      </Button>
                    </div>
                  </Col>

                </div>
              ) : (
                <Col span={24} style={{ paddingBottom: 10 }}>
                  <Alert
                    message="Selecione a instituição para listar as configurações de materiais"
                    type="info"
                    closeText={
                      <Icon style={{ color: '#1890ff' }} type="arrow-up" />
                    }
                    showIcon
                  />
                </Col>
              )}
            </Row>
          </div>
          ) : (
            <Row>
              <Col xs={24} sm={0}>
                <button
                  onClick={() => this.openModal(null)}
                  className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure"
                >
                  <Icon type="plus" fill="#ffffff" />
                </button>
              </Col>

              <Col xs={24} lg={8} sm={16}>
                <h1 className="title">Materiais</h1>
              </Col>

              <Col xs={24} lg={0} sm={0}>
                <div style={{ marginBottom: 10 }}>
                  <Search
                    placeholder="Buscar"
                    onChange={this.searchTable}
                    style={{ width: '100%', marginRight: 10 }}
                  />
                </div>
              </Col>

              <Col xs={0} lg={16} sm={8}>
                <div style={{ display: 'flex' }}>
                  <Search
                    placeholder="Buscar"
                    onChange={this.searchTable}
                    style={{ width: '100%', marginRight: 10 }}
                  />
                  <ImportExcel
                    table="materials"
                    title="Materiais"
                    type="materials"
                    reload={this.load}
                  />
                  <Button
                    type="primary"
                    shape="round"
                    className="add-procedure"
                    onClick={() => this.openModal()}
                  >
                    ADICIONAR
                  </Button>
                </div>
              </Col>
            </Row>
          )}

        <Row>
          {JSON.parse(localStorage.getItem('selected-institution'))
          && JSON.parse(localStorage.getItem('selected-institution')).is_group
          && !this.state.selectGroupInstitutionId ? (
            <div />
            ) : (
              <Table
                size="midle"
                loading={materials.loading}
                dataSource={this.state.dataMaterials}
                columns={columnsTableMaterials}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '30', '50', '100'],
                }}
                footer={() => {
                  const rowsDataSource = !this.state.dataMaterials
                    ? 0
                    : this.state.dataMaterials.length;
                  return (
                    <span>
                      Total de registros:
                      {' '}
                      <Tag>{rowsDataSource}</Tag>
                    </span>
                  );
                }}
              />
            )}
        </Row>

        <Modal
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          title={(
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleCancel}
              >
                <i className="icon-close" />
              </button>
              <span>
                {this.props.form.getFieldValue('id')
                  ? 'Editar material'
                  : 'Adicionar material'}
              </span>
            </div>
          )}
          footer={[
            <Button
              key="submit"
              type="primary"
              form="formMedicalPlan"
              htmlType="submit"
              loading={this.state.loadingSave}
            >
              Salvar
            </Button>,
          ]}
          bodyStyle={{ paddingTop: '21px' }}
          width="701px"
          zIndex={1024}
        >
          {this.state.selectGroupInstitutionId && (
            <div>
              {JSON.parse(localStorage.getItem('selected-institution'))
              && institutions.items
              && institutions.items.size > 1
                ? institutions.items
                  .filter((i) => i.id === this.state.selectGroupInstitutionId)
                  .map((institution) => (
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-label">
                        <label
                          className="ant-form-item-no-colon"
                          title="INSTITUIÇÃO"
                        >
                          INSTITUIÇÃO
                        </label>
                      </div>

                      <div
                        className="ant-list-item-meta ant-group"
                        style={{ marginTop: 10 }}
                      >
                        <div className="ant-list-item-meta-avatar">
                          <Avatar
                            shape="square"
                            src={
                                  institution.logo_url
                                    ? institution.logo_url
                                    : null
                                }
                            className="institution-logo"
                          >
                            Logo
                          </Avatar>
                        </div>
                        <div className="ant-list-item-meta-content">
                          <h4
                            className="ant-list-item-meta-title"
                            style={{ marginBottom: -5 }}
                          >
                            {stringsHelp.formatName(institution.name)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          )}
          <Form onSubmit={this.handleSubmit} id="formMedicalPlan">
            <Row gutter={28}>
              <Col md={24} lg={24} xl={24}>
                {getFieldDecorator('id', {})(<span />)}

                <FormItem {...formItemLayout} label="NOME">
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o nome do material.',
                      },
                    ],
                  })(<Input autoFocus />)}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={14}>
                <FormItem {...formItemLayout} label="CÓDIGO INTERNO">
                  {getFieldDecorator('internal_code', {})(<Input />)}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={10}>
                <FormItem {...formItemLayout} label="PREÇO">
                  {getFieldDecorator('price', {})(
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={numbers.numberToMoneyBR}
                    />,
                  )}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={24}>
                <FormItem {...formItemLayout} label="DESCRIÇÃO">
                  {getFieldDecorator('description', {})(
                    <TextArea style={{ marginTop: 5 }} rows={3} />,
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title=""
          visible={this.state.confirmVisible}
          onOk={() => this.handleDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={this.state.confirmLoading}
          onCancel={() => this.setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: '#ffcd56', marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>
            {' '}
            Confirma remover o material ?
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { materials, institutions } = state;
  return {
    materials,
    institutions,
  };
}

const connected = connect(mapStateToProps)(Form.create()(Materials));
export default connected;
